<template>
  <div class="page-class">
    <p class="back" @click="handleNavBack"><a>< 返回首页</a></p>

    <section class="body">
      <h1>我的班级</h1>

      <ul>
        <li v-for="item of classList" :key="item.id">
          <section>
            <h2>
              {{item.class_name}}
              <em class="finish" v-if="item.is_over == 1">已结课</em>
              <em v-else>进行中</em>
            </h2>
            <p class="desc"><img src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/379597b17383f304f8501972d0e3f8e5f6d4cdf34a878209357e4988d7ab427f.png" alt="">{{item.teacher}}</p>
          </section>
          <section>
            <p class="status">
              <template v-if="item.is_finsh == 0">你有作业未提交</template>
            </p>
            <button v-if="item.is_over == 1" @click="handleNavWork('')">查看作业本</button>
            <button class="continue" v-else @click="handleNavWork(item.id)">去完成</button>
          </section>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>

  import {apiUserClassList} from '@/api/api'

  export default {
    data() {
      return {
        classList: []
      }
    },
    created(){
      this.fetchClassList()
    },
    methods: {
      handleNavBack() {
        this.$router.go(-1)
      },
      async fetchClassList() {
        const {data} = await apiUserClassList()
        this.classList = [].concat(data).splice(0,3)
      },
      handleNavWork(id) {
        this.$router.push({
          name: 'ExerciseBook',
          query: {
            classId: id
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
.page-class {
  .back {
    margin-top: 10px;
    a {
      font-size: 14px;
      color: #3B86FF;
      cursor: pointer;
    }
  }

  .body {
    margin-top: 14px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    h1 {
      color: #1F1F40;
      padding: 30px 40px;
      box-shadow: 0 0 10px rgba(0,0,0,0.1);
      font-size: 20px;
    }
    ul {
      padding: 25px 40px;
      li {
        padding: 25px 0;
        border-bottom: 1px solid #EBEBF2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          color: #1F1F40;
          font-size: 16px;
          font-weight: normal;
          display: flex;
          align-items: center;
          em {
            padding: 2px 8px;
            border-radius: 12px;
            background-color: #ffdfe2;
            font-size: 14px;
            color: #FF606D;
            margin-left: 20px;
            &.finish {
              background-color: #d8f3ed;
              color: #3ec2a3;
            }
          }
        }
        .desc {
          margin-top: 22px;
          font-size: 16px;
          color: #CFCFCF;
          display: flex;
          align-items: center;
          img {
            width: 20px;
            margin-right: 8px;
          }
        }
        .status {
          height: 22px;
          font-size: 16px;
          color: #A4AFB7;
        }
        button {
          margin-top: 12px;
          padding: 5px 16px;
          border-radius: 5px;
          background-color: #ebebf2;
          font-size: 16px;
          color: #4A4A58;
          border: 0;
          min-width: 112px;
          cursor: pointer;
          &.continue {
            background-color: #3b86ff;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
